import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { AccountAppsGQL } from '@hxp/graphql';
import { HyAuthService } from '@hyland/ui';
import { Observable, of } from 'rxjs';
import { catchError, filter, map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class AuthGuard {
  constructor(
    private readonly hyAuthService: HyAuthService,
    private readonly accountAppsService: AccountAppsGQL,
    private readonly router: Router,
    private readonly location: Location,
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    const isUserAuthenticated = this.hyAuthService.authenticated;
    if (isUserAuthenticated) {
      return this.accountAppsService.watch().valueChanges.pipe(
        filter((resp) => !!resp.data),
        map((resp) => {
          if (resp.data.currentUser.accountApps.map((app) => app.appKey).includes('onboarding')) {
            return true;
          }

          this.redirectToUnauthorized(state);
          return false;
        }),
        catchError(() => {
          this.redirectToUnauthorized(state);

          return of(false);
        }),
      );
    }

    void this.hyAuthService.login({ state: { route: state.url } });
    return of(false);
  }

  private redirectToUnauthorized(state: RouterStateSnapshot) {
    void this.router.navigate(['/unauthorized'], { skipLocationChange: true }).then(() => {
      this.location.replaceState(state.url);
    });
  }
}
