<mat-drawer-container class="h-full">
  <mat-drawer mode="side" opened class="side-bar" [disableClose]="true">
    <hy-side-bar #sideBar="hySideBar">
      <mat-nav-list hyList class="pt-3">
        <a
          routerLinkActive
          #contractSearch="routerLinkActive"
          routerLink="./search"
          mat-list-item
          hyListItem
          data-testid="contract-search"
          [activated]="contractSearch.isActive || isPreviousRoute('search')"
          [hyListItemActive]="contractSearch.isActive || isPreviousRoute('search')"
        >
          Contract Search
        </a>
        <a
          routerLinkActive
          #recentContracts="routerLinkActive"
          routerLink="./recent"
          mat-list-item
          hyListItem
          data-testid="recent-contracts"
          [activated]="recentContracts.isActive || isPreviousRoute('recent')"
          [hyListItemActive]="recentContracts.isActive || isPreviousRoute('recent')"
        >
          Recent Contracts
        </a>
      </mat-nav-list>
    </hy-side-bar>
  </mat-drawer>

  <mat-drawer-content class="bg-white flex flex-col">
    <hy-side-bar-content class="flex flex-col grow">
      <router-outlet></router-outlet>
    </hy-side-bar-content>
  </mat-drawer-content>
</mat-drawer-container>
