<div class="grid grid-cols-2 gap-4 mb-4">
  <mat-form-field hyFormField>
    <mat-label>Account Name</mat-label>
    <input matInput value="{{ contractInfo.account.name }}" disabled />
  </mat-form-field>

  <mat-form-field hyFormField>
    <mat-label>Support Account Name</mat-label>
    <input matInput value="{{ contractInfo.supportAccount.name }}" disabled />
  </mat-form-field>

  <mat-form-field hyFormField>
    <mat-label>Source Account System</mat-label>
    <input matInput value="{{ contractInfo.account.sourceAccountSystem }}" disabled />
  </mat-form-field>

  <mat-form-field hyFormField>
    <mat-label>Source Account ID</mat-label>
    <input matInput value="{{ contractInfo.account.sourceAccountId }}" disabled />
  </mat-form-field>

  <mat-form-field hyFormField>
    <mat-label>Source Support Account System</mat-label>
    <input matInput value="{{ contractInfo.supportAccount.sourceAccountSystem }}" disabled />
  </mat-form-field>

  <mat-form-field hyFormField>
    <mat-label>Source Support Account ID</mat-label>
    <input matInput value="{{ contractInfo.supportAccount.sourceAccountId }}" disabled />
  </mat-form-field>

  <mat-form-field hyFormField>
    <mat-label>Source Contract System</mat-label>
    <input matInput value="{{ contractInfo.sourceContractSystem }}" disabled />
  </mat-form-field>

  <mat-form-field hyFormField>
    <mat-label>Source Contract ID</mat-label>
    <input matInput value="{{ contractInfo.sourceContractId }}" disabled />
  </mat-form-field>

  <mat-form-field hyFormField>
    <mat-label>Contract S3 Key</mat-label>
    <input matInput value="{{ contractInfo.hxpContractS3Key }}" disabled />
  </mat-form-field>

  <mat-form-field hyFormField>
    <mat-label>Contract S3 Bucket</mat-label>
    <input matInput value="{{ contractInfo.hxpContractS3BucketName }}" disabled />
  </mat-form-field>

  <mat-form-field hyFormField>
    <mat-label>Message ID</mat-label>
    <input matInput value="{{ contractInfo.messageId }}" disabled />
  </mat-form-field>

  <mat-form-field hyFormField>
    <mat-label>Contract Type</mat-label>
    <input matInput value="{{ contractInfo.type }}" disabled />
  </mat-form-field>
</div>

<form [formGroup]="emailsForm.form">
  <div class="grid grid-cols-2 gap-4">
    <div>
      <mat-form-field hyFormField>
        <mat-label>Admin Email</mat-label>
        <input
          #adminEmail
          formControlName="adminEmail"
          matInput
          (input)="emailsForm.checkOriginalAdminEmail(adminEmail.value)"
          value="{{ contractInfo.adminEmail }}"
        />
      </mat-form-field>
    </div>
    <div class="termEmail">
      <mat-form-field hyFormField>
        <mat-label>Term Email</mat-label>
        <input
          #termEmail
          formControlName="termEmail"
          matInput
          (input)="emailsForm.checkOriginalTermEmail(termEmail.value)"
          value="{{ contractInfo.contactEmail }}"
        />
      </mat-form-field>
    </div>
  </div>
</form>

<div class="button-row">
  <button
    mat-stroked-button
    *ngIf="!emailsForm.emailFieldIsBlank() && emailsForm.form.dirty"
    [disabled]="emailsForm.updateInProgress"
    (click)="emailsForm.onUpdateEmail()"
  >
    {{ emailsForm.updateEmailButtonText }}
  </button>
  <button
    mat-stroked-button
    *ngIf="!emailsForm.emailFieldIsBlank() && emailsForm.canResendEmail && emailsForm.form.pristine"
    [disabled]="emailsForm.updateInProgress"
    (click)="emailsForm.onResend()"
  >
    {{ emailsForm.resendEmailButtonText }}
  </button>
</div>
