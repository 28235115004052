import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './guards/auth.guard';
import { ContentCannotBeViewedView } from './views/content-cannot-be-viewed/content-cannot-be-viewed.view';
import { ContractView } from './views/contracts/contract/contract.view';
import { SearchView } from './views/contracts/search/search.view';
import { UnauthorizedView } from './views/unauthorized/unauthorized.view';
import { RecentContractsView } from './views/contracts/recent-contracts/recent-contracts';
import { SideNavView } from './views/contracts/side-nav/side-nav.view';

const routes: Routes = [
  {
    path: '',
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'contracts',
      },
      {
        path: 'contracts',
        component: SideNavView,
        children: [
          { path: '', pathMatch: 'full', redirectTo: 'search' },
          { path: 'recent', component: RecentContractsView },
          {
            path: 'search',
            component: SearchView,
            children: [
              {
                path: ':externalId',
                component: SearchView,
              },
            ],
          },
          { path: ':contractId', component: ContractView },
        ],
      },
    ],
  },
  {
    path: 'unauthorized',
    component: UnauthorizedView,
  },
  {
    path: '**',
    component: ContentCannotBeViewedView,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { bindToComponentInputs: true })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
