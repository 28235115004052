import { Location } from '@angular/common';
import { Component } from '@angular/core';

export interface NavigationState {
  navigatedFrom?: string;
}

@Component({
  selector: 'hxp-onboarding-support-views-side-nav',
  templateUrl: './side-nav.view.html',
  styleUrls: ['./side-nav.view.scss'],
})
export class SideNavView {
  constructor(private readonly _location: Location) {}

  isPreviousRoute(path: string) {
    const navigationState = this._location.getState() as NavigationState;
    return path === navigationState.navigatedFrom;
  }
}
