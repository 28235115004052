<div *ngIf="(!loading && contractFound); else loadingOrNotFound" class="m-3">
  <button mat-button type="button" class="mb-3" [hidden]="!hasNavigationState()" (click)="goBack()" color="primary">
    <mat-icon hyIcon>arrow_left</mat-icon>
    Return to Contract List
  </button>
  <div class="flex flex-col gap-4 w-full">
    <mat-card appearance="outlined" class="min-w-[700px]">
      <mat-card-header>
        <mat-card-title>Contract Status</mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <hxp-onboarding-support-contract-status-progress [contractStatus]="contract.status">
        </hxp-onboarding-support-contract-status-progress>
      </mat-card-content>
    </mat-card>

    <div class="flex flex-row flex-wrap gap-4">
      <mat-card appearance="outlined" class="min-w-[700px]">
        <mat-card-header>
          <mat-card-title>Contract Details</mat-card-title>
          <mat-card-subtitle>ID: {{ contract.id }}</mat-card-subtitle>
        </mat-card-header>
        <mat-card-content>
          <hxp-onboarding-support-contract-info [contractInfo]="contract"></hxp-onboarding-support-contract-info>
        </mat-card-content>
      </mat-card>
      <div class="flex-1 flex flex-col gap-4 min-w-[750px]">
        <mat-card appearance="outlined">
          <mat-card-header>
            <mat-card-title>Audit Log</mat-card-title>
          </mat-card-header>
          <mat-card-content>
            <hxp-onboarding-support-contract-log [logs]="contract.contractLog"></hxp-onboarding-support-contract-log>
          </mat-card-content>
        </mat-card>

        <mat-card appearance="outlined">
          <mat-card-header>
            <mat-card-title>Subscriptions</mat-card-title>
          </mat-card-header>
          <mat-card-content>
            <hxp-onboarding-support-contract-subscriptions [subscriptions]="contract.onboardingSubscriptions">
            </hxp-onboarding-support-contract-subscriptions>
          </mat-card-content>
        </mat-card>
      </div>
    </div>
  </div>
</div>
<ng-template #loadingOrNotFound>
  <div class="flex flex-col items-center justify-center w-full h-full">
    <mat-spinner *ngIf="loading"></mat-spinner>
    <div *ngIf="!contractFound && !loading" class="flex flex-col items-center justify-center">
      <p>The contract could not be found or you do not have the correct permissions to view it.</p>
      <button mat-stroked-button (click)="goBack()">Return to Contract List</button>
    </div>
  </div>
</ng-template>
