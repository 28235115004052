import { Component, Input } from '@angular/core';
import { ContractSubscription } from '@hxp/graphql';

type SubscriptionsInfo = Pick<ContractSubscription, 'id' | 'hxpEnvironmentId' | 'hxpEnvironmentTitle' | 'hxpSubscriptionId' | 'title'>;
@Component({
  selector: 'hxp-onboarding-support-contract-subscriptions',
  templateUrl: './contract-subscriptions.component.html',
  styleUrls: ['./contract-subscriptions.component.scss'],
})
export class ContractSubscriptionsComponent {
  @Input() subscriptions!: SubscriptionsInfo[];
}
