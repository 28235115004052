import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ContractStatus, ContractType, OnboardingContractsGQL, OnboardingContractsQuery, SourceSystem } from '@hxp/graphql';
import { HyToastService } from '@hyland/ui';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { Location } from '@angular/common';
import { ExternalRefTypes } from '../../../models/ExternalRefTypes';
import { NavigationState } from '../side-nav/side-nav.view';

type GetContractsContract = NonNullable<
  NonNullable<Pick<Required<OnboardingContractsQuery>, 'onboardingContracts'>['onboardingContracts']>[0]
>;

@Component({
  selector: 'hxp-onboarding-support-dashboard-view',
  templateUrl: './contract.view.html',
  styleUrls: ['./contract.view.scss'],
})
export class ContractView implements OnInit, OnDestroy {
  contract!: GetContractsContract;
  resendEmail = false;
  updateEmails = false;
  loading = false;
  contractFound = false;
  externalRefTypes = ExternalRefTypes;
  private readonly _unsubscribe$ = new Subject<void>();

  private readonly _emptyContract = {
    id: '',
    messageId: '',
    status: ContractStatus.Createsubscription,
    adminEmail: undefined,
    contactEmail: '',
    hxpContractS3BucketName: '',
    hxpContractS3Key: '',
    sourceContractId: '',
    sourceContractSystem: SourceSystem.Testsystem,
    contractLog: [],
    type: ContractType.Initialcontract,
    account: {
      id: '',
      name: '',
      termEmail: '',
      hxpAccountId: '',
      sourceAccountId: '',
      sourceAccountSystem: SourceSystem.Testsystem,
    },
    supportAccount: {
      id: '',
      name: '',
      sourceAccountId: '',
      sourceAccountSystem: SourceSystem.Testsystem,
    },
    onboardingSubscriptions: [],
  };

  constructor(
    private _contractsService: OnboardingContractsGQL,
    private route: ActivatedRoute,
    private toastService: HyToastService,
    private readonly _location: Location,
  ) {}

  ngOnInit(): void {
    this.loading = true;
    const contractNotFoundMessage = 'Error finding contract...';
    const contractId = this.route.snapshot.paramMap.get('contractId');
    if (contractId) {
      this._contractsService
        .watch({ contractId }, { pollInterval: 20000 })
        .valueChanges.pipe(
          filter((res) => !!res.data),
          takeUntil(this._unsubscribe$),
        )
        .subscribe({
          next: (res) => {
            if (res.data?.onboardingContracts && res.data?.onboardingContracts[0]) {
              this.contract = res.data.onboardingContracts[0];
              this.contractFound = true;
            } else {
              this.toastService.error(contractNotFoundMessage);
              this.contract = this._emptyContract;
              this.contractFound = false;
              this._unsubscribe$.next();
            }
            this.loading = false;
          },
          error: () => {
            this.toastService.error(contractNotFoundMessage);
            this.contract = this._emptyContract;
            this.contractFound = false;
            this.loading = false;
            this._unsubscribe$.next();
          },
        });
    } else {
      this.toastService.error('A Contract ID must be specified in the url');
      this.contract = this._emptyContract;
      this.contractFound = false;
      this.loading = false;
      this._unsubscribe$.next();
    }
  }

  ngOnDestroy(): void {
    this._unsubscribe$.next();
    this._unsubscribe$.complete();
  }

  hasNavigationState() {
    const navigationState = (this._location.getState() || {}) as NavigationState;
    return navigationState.navigatedFrom;
  }

  goBack() {
    this._location.back();
  }
}
