import { Pipe, PipeTransform } from '@angular/core';
import { EnvironmentStatus } from '../models/EnvironmentStatus';

const emptyGuid = '00000000-0000-0000-0000-000000000000';

@Pipe({
  // eslint-disable-next-line @angular-eslint/pipe-prefix
  name: 'environmentStatus',
})
// eslint-disable-next-line @angular-eslint/use-pipe-transform-interface
export class EnvironmentStatusPipe implements PipeTransform {
  transform(value: string): string {
    return value === emptyGuid ? EnvironmentStatus.NotAssigned : EnvironmentStatus.Assigned;
  }
}
