import { Component, Input } from '@angular/core';
import { ContractStatus } from '@hxp/graphql';

@Component({
  selector: 'hxp-onboarding-support-contract-status-progress',
  templateUrl: './contract-status-progress.component.html',
  styleUrls: ['./contract-status-progress.component.scss'],
})
export class ContractStatusProgressComponent {
  @Input() contractStatus!: ContractStatus;

  steps = [
    'Creating Subscription',
    'Terms of Use Email Sent',
    'Terms Accepted',
    'Sending Admin Email',
    'Admin Email Sent',
    'Admin Logged In',
    'Subscriptions Activated',
  ];

  contractStatusSteps: { [key in ContractStatus]: number } = {
    [ContractStatus.Createsubscription]: 0,
    [ContractStatus.Senttermsemail]: 1,
    [ContractStatus.Termsaccepted]: 2,
    [ContractStatus.Adminemailsending]: 3,
    [ContractStatus.Adminemailsent]: 4,
    [ContractStatus.Adminonboarded]: 5,
    [ContractStatus.Subscriptiononboarded]: this.steps.length,
  };
}
