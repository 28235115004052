import { Injectable } from '@angular/core';
import {
  ContractStatus,
  RepublishOnboardAdminEventGQL,
  ResendTermEmailGQL,
  UpdateAdminEmailGQL,
  UpdateTermEmailGQL,
} from '@hxp/graphql';
import { HyToastService } from '@hyland/ui';
import { AdminEditOnlyForm } from './admin-edit-only-form';
import { AdminEmailForm } from './admin-email-form';
import { EmailFormContract } from './email-form';
import { TermEmailForm } from './term-email-form';

@Injectable()
export class EmailFormFactory {
  constructor(
    private republishOnboardAdminEventService: RepublishOnboardAdminEventGQL,
    private resendTermEmailService: ResendTermEmailGQL,
    private updateAdminEmailService: UpdateAdminEmailGQL,
    private updateTermEmailService: UpdateTermEmailGQL,
    private toastService: HyToastService,
  ) {}

  create(emailFormContract: EmailFormContract) {
    switch (emailFormContract.status) {
      // Before terms have been accepted
      case ContractStatus.Createsubscription:
      case ContractStatus.Senttermsemail:
        return new TermEmailForm(
          emailFormContract,
          this.resendTermEmailService,
          this.updateTermEmailService,
          this.toastService,
        );
      case ContractStatus.Termsaccepted:
      case ContractStatus.Adminemailsending:
      case ContractStatus.Adminemailsent:
        return new AdminEmailForm(
          emailFormContract,
          this.republishOnboardAdminEventService,
          this.updateAdminEmailService,
          this.toastService,
        );
      default:
        return new AdminEditOnlyForm(
          emailFormContract,
          this.republishOnboardAdminEventService,
          this.updateAdminEmailService,
          this.toastService,
        );
    }
  }
}
