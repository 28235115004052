<div class="flex flex-col grow gap-4 p-6">
  <h2 class="hy-text-title">Contract Search</h2>
  <form [formGroup]="searchForm" (ngSubmit)="onSubmit()">
    <div class="flex gap-2 items-center">
      <mat-form-field class="w-[150px]" hyFormField data-testid="onboarding-search-by-form-field">
        <mat-label>Search By</mat-label>
        <mat-select [(value)]="searchByValue" formControlName="searchByValue">
          <mat-option [value]="searchCategory.SearchByHSI">HSI Number</mat-option>
          <mat-option [value]="searchCategory.SearchByAccountName">Account Name</mat-option>
          <mat-option [value]="searchCategory.SearchByAccountId">Account Id</mat-option>
          <mat-option [value]="searchCategory.SearchByContractId">Contract Id</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field
        class="w-[170px]"
        hyFormField
        *ngIf="searchByValue === searchCategory.SearchByAccountId || searchByValue === searchCategory.SearchByContractId"
      >
        <mat-label>In Source System</mat-label>
        <mat-select [(value)]="sourceSystemValue">
          <mat-option [value]="sourceSystemOptions.Testsystem">Test System</mat-option>
          <mat-option [value]="sourceSystemOptions.Demosystem">Demo System</mat-option>
          <mat-option [value]="sourceSystemOptions.Hylandsalesforce">Hyland Salesforce</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field class="w-[250px]" hyFormField data-testid="onboarding-search-bar-form-field">
        <mat-label *ngIf="searchByValue === searchCategory.SearchByHSI">Enter HSI Number</mat-label>
        <mat-label *ngIf="searchByValue === searchCategory.SearchByAccountName">Enter Account Name</mat-label>
        <mat-label *ngIf="searchByValue === searchCategory.SearchByAccountId">Enter Account Id</mat-label>
        <mat-label *ngIf="searchByValue === searchCategory.SearchByContractId">Enter Contract Id</mat-label>
        <input formControlName="searchParam" matInput required />
        <mat-error *ngIf="searchForm.controls.searchParam.hasError('required')">This field is required.</mat-error>
        <mat-error *ngIf="searchForm.controls.searchParam.hasError('pattern')">The value should be a number.</mat-error>
      </mat-form-field>
      <button color="primary" mat-flat-button type="submit" data-testid="onboarding-search-button">Search</button>
      <button mat-stroked-button type="button" (click)="resetForm()" data-testid="onboarding-reset-button">Reset</button>
    </div>
  </form>
  <hxp-onboarding-support-contracts-grid
    [enablePagination]="true"
    (contractSelected)="navigateToContract($event)"
    [isLoading]="isLoading"
    (nextPage)="onNextPage()"
    (previousPage)="onPreviousPage()"
    [contractData]="contractAndAccountData$ | async"
    [hasNextPage]="pageInfo.hasNextPage"
    [hasPreviousPage]="pageInfo.hasPreviousPage"
  ></hxp-onboarding-support-contracts-grid>
</div>
