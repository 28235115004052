// We use string enums, because the http request returns the enum as a string enum, see:
// https://stackoverflow.com/questions/45539033/angular-4-typescript-parsing-enum-interface-attribute-in-response-object
export enum ContractStatus {
  CreateSubscription = 'CREATESUBSCRIPTION',
  SentTermsEmail = 'SENTTERMSEMAIL',
  TermsAccepted = 'TERMSACCEPTED',
  AdminEmailSending = 'ADMINEMAILSENDING',
  AdminEmailSent = 'ADMINEMAILSENT',
  AdminOnboarded = 'ADMINONBOARDED',
  SubscriptionOnboarded = 'SUBSCRIPTIONONBOARDED',
}
