import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { HyShellModule } from '@hyland/ui-shell';

import { MaterialModule } from './material.module';

@NgModule({
  exports: [
    CommonModule,
    MaterialModule,
    FlexLayoutModule,
    HyShellModule,
  ],
})
export class SharedModule {}
