import { Component } from '@angular/core';

@Component({
  selector: 'hxp-onboarding-support-views-content-cannot-be-viewed',
  templateUrl: './content-cannot-be-viewed.view.html',
  styleUrls: ['./content-cannot-be-viewed.view.scss'],
})
export class ContentCannotBeViewedView {
  appType = 'Onboarding Support Portal';
}
