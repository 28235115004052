<div class="flex flex-col grow p-6" *ngIf="pageInfo$ | async; let pageInfo">
  <h2 class="hy-text-title">Recently Created Contracts</h2>
  <p>The following contracts were created after {{ earliestContractCreatedDate.toDateString() }}</p>
  <hxp-onboarding-support-contracts-grid
    [enablePagination]="true"
    (contractSelected)="navigateToContract($event)"
    [isLoading]="(isLoading$ | async) || false"
    (nextPage)="onNextPage()"
    (previousPage)="onPreviousPage()"
    [hasNextPage]="pageInfo.hasNextPage"
    [hasPreviousPage]="pageInfo.hasPreviousPage"
    [contractData]="contractAndAccountData$ | async"
  ></hxp-onboarding-support-contracts-grid>
</div>
