import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ContractAndAccountViewGQL, ContractAndAccountViewQueryVariables, PageInfo, SourceSystem } from '@hxp/graphql';
import { isNotNullOrUndefined } from '@hxp/kernel';

import { Subject, Subscription } from 'rxjs';
import { map, takeUntil, tap } from 'rxjs/operators';
import { Maybe } from 'graphql/jsutils/Maybe';
import { ContractGridItem } from '../../../components/contracts-grid/contracts-grid.component';
import { NavigationState } from '../side-nav/side-nav.view';

export enum SearchCategory {
  SearchByHSI = 'searchByHSI',
  SearchByAccountName = 'searchByAccountName',
  SearchByAccountId = 'searchByAccountId',
  SearchByContractId = 'searchByContractId',
}

@Component({
  selector: 'hxp-onboarding-support-search-view',
  templateUrl: './search.view.html',
  styleUrls: ['./search.view.scss'],
})
export class SearchView implements OnInit, OnDestroy {
  readonly sourceSystemOptions = SourceSystem;
  readonly searchCategory = SearchCategory;

  searchForm!: FormGroup;
  searchByValue = SearchCategory.SearchByHSI;
  sourceSystemValue = SourceSystem.Hylandsalesforce;
  searchParam = '';
  readonly numberValidator = Validators.pattern('^[0-9]*$');

  contractAndAccountSubscription?: Subscription;
  queryVariables: ContractAndAccountViewQueryVariables = {};

  isLoading = false;
  private readonly _emptyPageInfo = { hasNextPage: false, hasPreviousPage: false };
  pageInfo: PageInfo = this._emptyPageInfo;
  private readonly _numberOfQueryResults = 50;

  fromBackButton = false;

  private readonly _contractAndAccountData$ = new Subject<Maybe<ContractGridItem[]>>();
  contractAndAccountData$ = this._contractAndAccountData$.asObservable();

  private readonly _unsubscribe$ = new Subject<void>();

  constructor(
    private _contractAndAccountViewService: ContractAndAccountViewGQL,
    private _router: Router,
    private _route: ActivatedRoute,
  ) {}

  @HostListener('window:popstate', ['$event'])
  onPopState() {
    this._unsubscribeFromQuery();
    this.fromBackButton = true;
    this.pageInfo = this._emptyPageInfo;
    this.ngOnInit();
  }

  ngOnInit(): void {
    this.searchForm = new FormGroup({
      searchParam: new FormControl('', [Validators.required, this.numberValidator]),
      searchByValue: new FormControl(SearchCategory.SearchByHSI, { nonNullable: true }),
    });

    this.searchForm.controls.searchByValue.valueChanges
      .pipe(
        tap((searchBy) => {
          this.searchByValue = searchBy;

          if (searchBy === SearchCategory.SearchByHSI) {
            this.searchForm.get('searchParam')?.setValidators([Validators.required, this.numberValidator]);
          } else {
            this.searchForm.get('searchParam')?.setValidators(Validators.required);
          }

          this.searchForm.get('searchParam')?.updateValueAndValidity();
        }),
        takeUntil(this._unsubscribe$),
      )
      .subscribe();

    this.searchParam = this._route.snapshot.paramMap.get('searchParam') || '';
    this.searchForm.controls.searchParam.setValue(this.searchParam);
    if (this.searchParam) {
      this.onSubmit();
    }
  }

  ngOnDestroy(): void {
    this._unsubscribe$.next();
    this._unsubscribe$.complete();
  }

  onSubmit(): void {
    this._setLoadingUI();
    this.searchParam = this.searchForm.get('searchParam')?.value;

    if (!this.searchParam) {
      this.isLoading = false;
      this.searchForm.controls.searchParam.setErrors({ incorrect: true });
      this.pageInfo = this._emptyPageInfo;
    } else {
      this.queryVariables = this._getContractAndAccountFilterQueryVariables();
      this._getContractAndAccountInfo();
    }

    if (!this.fromBackButton) {
      void this._router.navigate(['.', this.searchParam], { relativeTo: this._route });
    }
    this.fromBackButton = false;
  }

  onNextPage(): void {
    const variables = { ...this.queryVariables };
    variables.after = this.pageInfo.endCursor;
    variables.first = this._numberOfQueryResults;

    this._setLoadingUI();
    this._getContractAndAccountInfo(variables);
  }

  onPreviousPage(): void {
    const variables = { ...this.queryVariables };
    variables.before = this.pageInfo.startCursor;
    variables.last = this._numberOfQueryResults;

    this._setLoadingUI();
    this._getContractAndAccountInfo(variables);
  }

  resetForm(): void {
    this.searchForm.reset();
    void this._router.navigate(['.', ''], { relativeTo: this._route });
    this.isLoading = false;
    this._unsubscribeFromQuery();
    this._contractAndAccountData$.next(undefined);
    this.searchParam = '';
    this.pageInfo = this._emptyPageInfo;
    this.searchForm.controls.searchParam.setErrors(null);
  }

  navigateToContract(contractAndAccountInfo: ContractGridItem): void {
    void this._router.navigate(['/contracts', contractAndAccountInfo.contractId], {
      state: { navigatedFrom: this._route.snapshot.url.map((url) => url.path).join('/') } as NavigationState,
    });
  }

  private _setLoadingUI() {
    this._contractAndAccountData$.next(undefined);
    this.isLoading = true;
    this._unsubscribeFromQuery();
  }

  private _getContractAndAccountInfo(variables?: ContractAndAccountViewQueryVariables) {
    if (!variables) {
      variables = this.queryVariables;
    }
    this.contractAndAccountSubscription = this._contractAndAccountViewService
      .watch(variables)
      .valueChanges.pipe(
        tap((res) => (this.pageInfo = res.data.ContractAndAccountView?.pageInfo || this._emptyPageInfo)),
        map((res) => res.data.ContractAndAccountView?.edges?.map((edge) => edge.node).filter(isNotNullOrUndefined) || []),
        takeUntil(this._unsubscribe$),
      )
      .subscribe({
        next: (contractAndAccountNodes) => {
          if (contractAndAccountNodes.length) {
            this._contractAndAccountData$.next(
              contractAndAccountNodes.map((node) => {
                return {
                  accountName: node.accountName,
                  contractCreatedDateTime: node.contractCreatedDateTime,
                  contractId: node.contractId,
                  contractStatus: node.contractStatus,
                  contractState: node.contractState,
                  contractType: node.contractType,
                };
              }),
            );
            // eslint-disable-next-line no-null/no-null
            this.searchForm.controls.searchParam.setErrors(null);
          } else {
            this.searchForm.controls.searchParam.setErrors({ incorrect: true });
            this._contractAndAccountData$.next([]);
            this.pageInfo = this._emptyPageInfo;
          }

          this.isLoading = false;
        },
        error: () => {
          this.isLoading = false;
          this.searchForm.controls.searchParam.setErrors({ incorrect: true });
          this._contractAndAccountData$.next([]);
          this.pageInfo = this._emptyPageInfo;
        },
      });
  }

  private _getContractAndAccountFilterQueryVariables(): ContractAndAccountViewQueryVariables {
    switch (this.searchByValue) {
      case 'searchByHSI':
        // In the case where 'Number' can't parse and returns 'NaN', a query with -1 will return an empty result.
        // Otherwise, 'null' would be sent for the 'eq:' value and graphQL returns every record
        return { where: { hsiNumber: { eq: Number(this.searchParam) || -1 } } } as ContractAndAccountViewQueryVariables;
      case 'searchByAccountName':
        return { where: { accountName: { eq: this.searchParam } } } as ContractAndAccountViewQueryVariables;
      case 'searchByAccountId':
        return {
          where: {
            and: [{ sourceAccountSystem: { eq: this.sourceSystemValue } }, { sourceAccountId: { eq: this.searchParam } }],
          },
        } as ContractAndAccountViewQueryVariables;
      case 'searchByContractId':
        return {
          where: {
            and: [{ sourceContractSystem: { eq: this.sourceSystemValue } }, { sourceContractId: { eq: this.searchParam } }],
          },
        } as ContractAndAccountViewQueryVariables;
      default:
        return { where: undefined } as ContractAndAccountViewQueryVariables;
    }
  }

  private _unsubscribeFromQuery(): void {
    this.contractAndAccountSubscription?.unsubscribe();
  }
}
