import { ResendTermEmailGQL, UpdateTermEmailGQL } from '@hxp/graphql';
import { HyToastService } from '@hyland/ui';
import { EmailForm, EmailFormContract } from './email-form';

export class TermEmailForm extends EmailForm {
  canResendEmail = true;
  updateEmailButtonText = 'Update Term Email';
  resendEmailButtonText = 'Resend Term Email';

  constructor(
    emailFormContract: EmailFormContract,
    private readonly _resendTermEmailService: ResendTermEmailGQL,
    private readonly _updateTermEmailService: UpdateTermEmailGQL,
    toastService: HyToastService,
  ) {
    super(emailFormContract, toastService);

    this.form.get('adminEmail')?.disable();
  }

  emailFieldIsBlank() {
    return !this.form.get('termEmail')?.value;
  }

  _resendEmail() {
    const resendEmailErrorText = 'Error Resending Term Email';
    this._resendTermEmailService
      .mutate({
        contractId: this.emailFormContract.id,
      })
      .subscribe({
        next: (res) => {
          if (res.data?.onboardingAccountResendTermEmail?.value) {
            this.toastService.success('Successfully Sent Term Email');
          } else {
            this.toastService.error(resendEmailErrorText);
          }
          this.updateInProgress = false;
        },
        error: () => {
          this.toastService.error(resendEmailErrorText);
          this.updateInProgress = false;
        },
        complete: () => {
          this.updateInProgress = false;
        },
      });
  }

  _updateEmail() {
    const updateEmailErrorText = 'Error Updating Term Email';
    const email = this.form.get('termEmail')?.value;
    this._updateTermEmailService
      .mutate({
        contractId: this.emailFormContract.id,
        updatedTermEmail: email,
      })
      .subscribe({
        next: (res) => {
          const updatedEmail = res.data?.onboardingAccountUpdateTermEmail?.value?.contactEmail;
          if (updatedEmail) {
            this.toastService.success(`Successfully Updated Term Email to: ${updatedEmail}`);
            this.onUpdateEmailResponse();
          } else {
            this.toastService.error(updateEmailErrorText);
          }
        },
        error: () => {
          this.toastService.error(updateEmailErrorText);
          this.updateInProgress = false;
        },
        complete: () => {
          this.updateInProgress = false;
        },
      });
  }
}
