import { Pipe, PipeTransform } from '@angular/core';
import { ContractStatus } from '../models/ContractStatus';

// eslint-disable-next-line @angular-eslint/pipe-prefix
@Pipe({ name: 'contractStatus' })
// eslint-disable-next-line @angular-eslint/use-pipe-transform-interface
export class ContractStatusPipe implements PipeTransform {
  steps = [
    'Creating Subscription',
    'Terms of Use Email Sent',
    'Terms Accepted',
    'Sending Admin Email',
    'Admin Email Sent',
    'Admin Logged In',
    'Subscriptions Activated',
  ];

  transform(value: string): string {
    switch (value) {
      case ContractStatus.CreateSubscription:
        return this.steps[0];
      case ContractStatus.SentTermsEmail:
        return this.steps[1];
      case ContractStatus.TermsAccepted:
        return this.steps[2];
      case ContractStatus.AdminEmailSending:
        return this.steps[3];
      case ContractStatus.AdminEmailSent:
        return this.steps[4];
      case ContractStatus.AdminOnboarded:
        return this.steps[5];
      case ContractStatus.SubscriptionOnboarded:
        return this.steps[6];
    }
    return value;
  }
}
