export const isNotNullOrUndefined = <T>(value: T | null | undefined): value is NonNullable<T> => {
  // eslint-disable-next-line no-null/no-null
  if (value === null || value === undefined) {
    return false;
  }
  // A quick double check that we didn't mess up the short circuit condition above and let
  // null or undefined values through
  const _dummyVarForCompiler: T = value;
  return true;
};
