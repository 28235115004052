<ng-container *ngIf="{ isLoading: isLoading$ | async }; let loadingContext">
  <div class="flex flex-col grow">
    <div
      class="w-full overflow-auto"
      [class.grow]="!isContractListEmpty(loadingContext.isLoading || false)"
      [class.h-[70px]]="isContractListEmpty(loadingContext.isLoading || false)"
    >
      <hy-content-list
        [dataSource]="contractAndAccountViewDataSource"
        [loading]="loadingContext.isLoading || false"
        (activeRowChange)="contractSelected.emit($event)"
      >
        <hy-content-list-column propertyName="accountName" [sortable]="true" label="Account Name"> </hy-content-list-column>
        <hy-content-list-column propertyName="contractStatus" [sortable]="true" label="Status">
          <ng-container *hyContentListCell="let row">
            <span>{{ row.contractState | titlecase }} - {{ row.contractStatus | contractStatus }}</span>
          </ng-container>
        </hy-content-list-column>
        <hy-content-list-column propertyName="contractType" [sortable]="true" label="Type"></hy-content-list-column>
        <hy-content-list-column propertyName="contractCreatedDateTime" [sortable]="true" label="Date Created">
          <ng-container *hyContentListCell="let row">
            <span>{{ row.contractCreatedDateTime | date: 'full' }}</span>
          </ng-container>
        </hy-content-list-column>
        <hy-content-list-column propertyName="contractId" [sortable]="false" label="ID"></hy-content-list-column>
      </hy-content-list>
    </div>
    <div class="pt-3 flex gap-4" *ngIf="enablePagination && (hasPreviousPage || hasNextPage)">
      <button mat-stroked-button type="button" [disabled]="!hasPreviousPage" (click)="previousPage.emit()">Previous</button>
      <button mat-stroked-button type="button" [disabled]="!hasNextPage" (click)="nextPage.emit()">Next</button>
    </div>
    <div *ngIf="isContractListEmpty(loadingContext.isLoading || false)" class="flex w-full justify-center">
      <div class="mat-headline-1">No Contracts Found</div>
    </div>
  </div>
</ng-container>
