import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { OnboardingAccount, OnboardingContract } from '@hxp/graphql';
import { EmailForm } from './email-form/email-form';
import { EmailFormFactory } from './email-form/email-form-factory';

type ContractForContractInfo = Pick<
  OnboardingContract,
  | 'messageId'
  | 'hxpContractS3BucketName'
  | 'hxpContractS3Key'
  | 'adminEmail'
  | 'contactEmail'
  | 'id'
  | 'status'
  | 'sourceContractId'
  | 'sourceContractSystem'
  | 'type'
>;
type AccountForContractInfo = Pick<OnboardingAccount, 'termEmail' | 'name' | 'hxpAccountId' | 'sourceAccountSystem' | 'sourceAccountId'>;
type SupportAccountForContractInfo = Pick<OnboardingAccount, 'name' | 'id' | 'sourceAccountSystem' | 'sourceAccountId'>;

export type ContractInfo = ContractForContractInfo & { account: AccountForContractInfo } & {
  supportAccount: SupportAccountForContractInfo;
};

@Component({
  selector: 'hxp-onboarding-support-contract-info',
  templateUrl: './contract-info.component.html',
  styleUrls: ['./contract-info.component.scss'],
  providers: [EmailFormFactory],
})
export class ContractInfoComponent implements OnChanges {
  @Input() contractInfo!: ContractInfo;

  emailsForm!: EmailForm;

  constructor(private emailFormFactory: EmailFormFactory) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.contractInfo && changes.contractInfo.currentValue) {
      this.emailsForm = this.emailFormFactory.create(this.contractInfo);
    }
  }
}
