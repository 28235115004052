<div *ngIf="subscriptions.length > 0; else noSubscriptionsCreated" class="table-holder">
  <hy-content-list [dataSource]="subscriptions">
    <hy-content-list-column propertyName="title" [sortable]="true" label="Subscription Title"></hy-content-list-column>
    <hy-content-list-column
      propertyName="hxpSubscriptionId"
      [sortable]="true"
      label="Subscription Id"
    ></hy-content-list-column>
    <hy-content-list-column propertyName="hxpEnvironmentTitle" [sortable]="true" label="Environment Name">
      <ng-container *hyContentListCell="let row">
        <span>{{ row.hxpEnvironmentTitle }}</span>
      </ng-container>
    </hy-content-list-column>
    <hy-content-list-column propertyName="hxpEnvironmentId" [sortable]="true" label="Environment Id"></hy-content-list-column>
    <hy-content-list-column propertyName="this" [sortable]="true" label="Status">
      <ng-container *hyContentListCell="let row">
        <span>{{ row.hxpEnvironmentId | environmentStatus }}</span>
      </ng-container>
    </hy-content-list-column>
  </hy-content-list>
</div>

<ng-template #noSubscriptionsCreated>
  <div class="center-holder">
    <div class="mat-headline-1 centered">No Subscriptions Created</div>
  </div>
</ng-template>
