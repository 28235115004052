import { Component } from '@angular/core';
import { AppsSwitcherService } from '@hxp/kernel';

@Component({
  selector: 'hxp-onboarding-support-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  readonly accountApps$ = this._switcherApps.accountApps$;

  constructor(private readonly _switcherApps: AppsSwitcherService) {}
}
