import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { ContractState, ContractStatus, ContractType } from '@hxp/graphql';
import { HyContentListDataSource } from '@hyland/ui';
import { Maybe } from 'graphql/jsutils/Maybe';
import { ReplaySubject } from 'rxjs';

export interface ContractGridItem {
  accountName: string;
  contractCreatedDateTime: Date;
  contractId: string;
  contractState: ContractState;
  contractStatus: ContractStatus;
  contractType: ContractType;
}

@Component({
  selector: 'hxp-onboarding-support-contracts-grid',
  templateUrl: './contracts-grid.component.html',
  styleUrls: ['./contracts-grid.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContractsGridComponent {
  @Input()
  set contractData(data: Maybe<ContractGridItem[]>) {
    this.contractDataPopulated = !!data;
    this.contractAndAccountViewDataSource.data = data || [];
  }

  @Input()
  enablePagination = false;

  @Input()
  hasNextPage? = false;

  @Input()
  hasPreviousPage? = false;

  @Input()
  set isLoading(isLoadingParam: boolean) {
    this._isLoading$.next(isLoadingParam);
  }
  private readonly _isLoading$ = new ReplaySubject<boolean>(1);
  isLoading$ = this._isLoading$.asObservable();

  contractAndAccountViewDataSource = new HyContentListDataSource<ContractGridItem>();
  contractDataPopulated = false;
  isContractListEmpty(isLoading: boolean) {
    return !isLoading && this.contractDataPopulated && !this.contractAndAccountViewDataSource.data.length;
  }

  @Output()
  nextPage = new EventEmitter<void>();

  @Output()
  previousPage = new EventEmitter<void>();

  @Output()
  contractSelected = new EventEmitter<ContractGridItem>();
}
