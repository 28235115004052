import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Maybe, PageInfo, RecentContractsGQL, RecentContractsQuery, RecentContractsQueryVariables } from '@hxp/graphql';
import { BehaviorSubject, Observable, ReplaySubject, Subject, map, mergeMap, switchMap, take, takeUntil, tap } from 'rxjs';
import { isNotNullOrUndefined } from '@hxp/kernel';
import { ApolloQueryResult } from '@apollo/client';
import { sub } from 'date-fns/sub';
import { ContractGridItem } from '../../../components/contracts-grid/contracts-grid.component';
import { NavigationState } from '../side-nav/side-nav.view';

enum PaginationEvent {
  NextPage,
  PreviousPage,
  Reset,
}

@Component({
  selector: 'hxp-onboarding-support-views-recent-contracts',
  templateUrl: './recent-contracts.view.html',
  styleUrls: ['./recent-contracts.view.scss'],
})
export class RecentContractsView implements OnInit, OnDestroy {
  readonly earliestContractCreatedDate = sub(new Date(), {
    months: 1,
  });

  private readonly _contractAndAccountData$ = new Subject<Maybe<ContractGridItem[]>>();
  contractAndAccountData$ = this._contractAndAccountData$.asObservable();

  private readonly _pageUpdate$ = new BehaviorSubject<PaginationEvent>(PaginationEvent.Reset);

  private readonly _isLoading$ = new ReplaySubject<boolean>(1);
  readonly isLoading$ = this._isLoading$.asObservable();

  private readonly _numberOfQueryResults = 50;
  private readonly _pageInfo$ = new BehaviorSubject<Partial<PageInfo>>({});
  readonly pageInfo$ = this._pageInfo$.asObservable();

  private readonly _unsubscribe$ = new Subject<void>();
  private readonly _recentContractsQuery$: Observable<ApolloQueryResult<RecentContractsQuery>> = this._pageUpdate$.pipe(
    tap((_paginationEvent) => {
      this._isLoading$.next(true);
    }),
    mergeMap((paginationEvent) => {
      return this.pageInfo$.pipe(
        map((currentPageInfo) => {
          const queryVars: RecentContractsQueryVariables = { createdAfter: this.earliestContractCreatedDate };

          if (paginationEvent === PaginationEvent.Reset || !this._isValidPaginationEvent(paginationEvent, currentPageInfo)) {
            return { ...queryVars, first: this._numberOfQueryResults };
          }

          if (paginationEvent === PaginationEvent.NextPage) {
            return {
              ...queryVars,
              after: currentPageInfo.endCursor,
              first: this._numberOfQueryResults,
            };
          } else {
            return {
              ...queryVars,
              before: currentPageInfo.startCursor,
              last: this._numberOfQueryResults,
            };
          }
        }),
        take(1),
        switchMap((variables: RecentContractsQueryVariables) => this._getQuery(variables)),
      );
    }),
  );

  constructor(
    private _recentContractsService: RecentContractsGQL,
    private _router: Router,
    private _route: ActivatedRoute,
  ) {}

  ngOnInit(): void {
    this._recentContractsQuery$
      .pipe(
        map((res) => res.data.ContractAndAccountView?.edges?.map((edge) => edge.node).filter(isNotNullOrUndefined) || []),
        takeUntil(this._unsubscribe$),
      )
      .subscribe({
        next: (contractAndAccountNodes) => {
          if (contractAndAccountNodes.length) {
            this._contractAndAccountData$.next(
              contractAndAccountNodes.map((node) => {
                return {
                  accountName: node.accountName,
                  contractCreatedDateTime: node.contractCreatedDateTime,
                  contractId: node.contractId,
                  contractStatus: node.contractStatus,
                  contractState: node.contractState,
                  contractType: node.contractType,
                };
              }),
            );
          } else {
            this._contractAndAccountData$.next([]);
            this._pageInfo$.next({});
          }

          this._isLoading$.next(false);
        },
        error: () => {
          this._isLoading$.next(false);
          this._contractAndAccountData$.next([]);
          this._pageInfo$.next({});
        },
      });
  }

  ngOnDestroy(): void {
    this._unsubscribe$.next();
    this._unsubscribe$.complete();
  }

  navigateToContract(contractAndAccountInfo: ContractGridItem): void {
    void this._router.navigate(['/contracts', contractAndAccountInfo.contractId], {
      state: { navigatedFrom: this._route.snapshot.url.map((url) => url.path).join('/') } as NavigationState,
    });
  }

  onNextPage(): void {
    this._pageUpdate$.next(PaginationEvent.NextPage);
  }

  onPreviousPage(): void {
    this._pageUpdate$.next(PaginationEvent.PreviousPage);
  }

  private _getQuery(variables: RecentContractsQueryVariables) {
    return this._recentContractsService.watch(variables).valueChanges.pipe(
      tap((res) => this._pageInfo$.next(res.data.ContractAndAccountView?.pageInfo || {})),
      takeUntil(this._unsubscribe$),
    );
  }

  private _isValidPaginationEvent(paginationEvent: PaginationEvent, pageState?: Partial<PageInfo>) {
    if (paginationEvent === PaginationEvent.Reset) {
      return true;
    } else if (!pageState) {
      return false;
    }

    return (
      (paginationEvent === PaginationEvent.NextPage && pageState.hasNextPage && pageState.endCursor) ||
      (paginationEvent === PaginationEvent.PreviousPage && pageState.hasPreviousPage && pageState.startCursor)
    );
  }
}
