import { RepublishOnboardAdminEventGQL, UpdateAdminEmailGQL } from '@hxp/graphql';
import { HyToastService } from '@hyland/ui';
import { AdminEmailForm } from './admin-email-form';
import { EmailFormContract } from './email-form';

export class AdminEditOnlyForm extends AdminEmailForm {
  constructor(
    emailFormContract: EmailFormContract,
    republishOnboardAdminEventService: RepublishOnboardAdminEventGQL,
    updateAdminEmailService: UpdateAdminEmailGQL,
    toastService: HyToastService,
  ) {
    super(emailFormContract, republishOnboardAdminEventService, updateAdminEmailService, toastService);

    this.canResendEmail = false;
  }
}
