import { Component, Input } from '@angular/core';
import { ContractLog } from '@hxp/graphql';

type Log = Pick<ContractLog, 'id' | 'eventDateTime' | 'userId' | 'message'>;

@Component({
  selector: 'hxp-onboarding-support-contract-log',
  templateUrl: './contract-log.component.html',
  styleUrls: ['./contract-log.component.scss'],
})
export class ContractLogComponent {
  @Input() logs!: Log[];
}
