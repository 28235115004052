import { RepublishOnboardAdminEventGQL, UpdateAdminEmailGQL } from '@hxp/graphql';
import { HyToastService } from '@hyland/ui';
import { EmailForm, EmailFormContract } from './email-form';

export class AdminEmailForm extends EmailForm {
  canResendEmail = true;
  updateEmailButtonText = 'Update Admin Email';
  resendEmailButtonText = 'Resend Admin Email';

  constructor(
    emailFormContract: EmailFormContract,
    protected readonly _republishOnboardAdminEventService: RepublishOnboardAdminEventGQL,
    protected readonly _updateAdminEmailService: UpdateAdminEmailGQL,
    toastService: HyToastService,
  ) {
    super(emailFormContract, toastService);
    this.form.get('termEmail')?.disable();
  }

  emailFieldIsBlank() {
    return !this.form.get('adminEmail')?.value;
  }

  _resendEmail() {
    const resendEmailErrorText = 'Error Republishing Onboard Admin Event';
    this._republishOnboardAdminEventService
      .mutate({
        contractId: this.emailFormContract.id,
      })
      .subscribe({
        next: (res) => {
          if (res.data?.onboardingAccountRepublishAdminEvent?.value) {
            this.toastService.success('Successfully Republished Onboard Admin Event');
          } else {
            this.toastService.error(resendEmailErrorText);
          }
        },
        error: () => {
          this.toastService.error(resendEmailErrorText);
          this.updateInProgress = false;
        },
        complete: () => {
          this.updateInProgress = false;
        },
      });
  }

  _updateEmail() {
    const updateEmailErrorText = 'Error Updating Admin Email';
    const email = this.form.get('adminEmail')?.value;
    this._updateAdminEmailService
      .mutate({
        contractId: this.emailFormContract.id,
        updatedAdminEmail: email,
      })
      .subscribe({
        next: (res) => {
          const updatedEmail = res.data?.onboardingContractUpdateAdminEmail?.value?.adminEmail;
          if (updatedEmail) {
            this.toastService.success(`Successfully Updated Admin Email to: ${updatedEmail}`);
            this.onUpdateEmailResponse();
          } else {
            this.toastService.error(updateEmailErrorText);
          }
        },
        error: () => {
          this.toastService.error(updateEmailErrorText);
          this.updateInProgress = false;
        },
        complete: () => {
          this.updateInProgress = false;
        },
      });
  }
}
